<template>
  <div>
    <div v-if="applicationsCompLength" class="table-responsive">
      <table class="table table-hover text-left">
        <thead>
        <tr>
          <th scope="col">id</th>
          <th scope="col">Firstname</th>
          <th scope="col">Lastname</th>
          <th scope="col">DOB</th>
          <th scope="col">Email</th>
          <th scope="col">NI Number</th>
          <th scope="col">Date</th>
          <th scope="col">Traces</th>
          <th scope="col">Addresses</th>
          <th scope="col">Contacts</th>
          <th scope="col">Status</th>
          <th scope="col">Copy data</th>
          <th scope="col">Move traces</th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="(application, index) in applicationsComp"
            :key="index"
            class="table-row"
        >
          <td scope="row">
            <span class="text-subdued">{{ application.id }}</span>
          </td>
          <td scope="row">
              <span class="link-text" @click="handleSelect(application)">{{
                  application.first_name
                }}</span>
          </td>
          <td>
              <span class="link-text" @click="handleSelect(application)">{{
                  application.last_name
                }}</span>
          </td>
          <td>
              <span class="link-text" @click="handleSelect(application)">{{
                  formatReadDate(application.dob)
                }}</span>
          </td>
          <td>
              <span class="link-text" @click="handleSelect(application)">{{
                  application.email
                }}</span>
          </td>
          <td>
              <span class="link-text" @click="handleSelect(application)">{{
                  application.ni_number
                }}</span>
          </td>
          <td>{{ formatReadDate(application.created_at) }}</td>
          <td>{{ application.trace }}</td>
          <td>{{ application.addresses }}</td>
          <td>{{ application.contact }}</td>
          <td>
            <StatusUpdate
                :ref="'statusUpdateComponent_' + index"
                :entity="application"
                :with-title="false"
                type="app"
                @status-changed="handleStatusChange"
            />
          </td>
          <td>
            <LoadingButton
                v-if="application.status != 'in-progress'"
                :processing="processingCopy"
                btn-text="copy"
                class="btn btn-success"
                processing-text="copying..."
                @click.prevent="handleCopy(index)"
            />
          </td>
          <td>
            <LoadingButton
                v-if="
                  application.status != 'in-progress' && application.trace > 0
                "
                :processing="processingTrace"
                btn-text="move"
                class="btn btn-warning"
                processing-text="moving..."
                @click.prevent="handleMoveTrace(application.uuid)"
            />
          </td>
          <EditModal :ref="'copyData_' + index" @closed="handleCopyClose">
            <template #formTitle> Copy data to app id: {{ toId }}</template>
            <template #form>
              <Form
                  :validation-schema="schema"
                  class="w-100 text-left"
                  @submit="copyData"
              >
                <div class="row">
                  <div class="col-6">
                    <CheckInput
                        :label="
                          'First name: ( ' + application.first_name + ' )'
                        "
                        :value="application.first_name"
                        name="first_name"
                        type="radio"
                        @updated="handleCopyUpdate"
                    />
                  </div>
                  <div class="col-6">
                    <CheckInput
                        :label="'Last name: ( ' + application.last_name + ' )'"
                        :value="application.last_name"
                        name="last_name"
                        type="radio"
                        @updated="handleCopyUpdate"
                    />
                  </div>
                  <div class="col-6">
                    <CheckInput
                        :label="'Date of birth: ( ' + application.dob + ' )'"
                        :value="application.dob"
                        name="dob"
                        type="radio"
                        @updated="handleCopyUpdate"
                    />
                  </div>
                  <!-- <div class="col-6">
                    <CheckInput
                      type="radio"
                      name="email"
                      :label="'Email: ( ' + application.email + ' )'"
                      :value="application.email"
                      @updated="handleCopyUpdate"
                    />
                  </div> -->
                  <div class="col-6">
                    <CheckInput
                        :label="'NI number: ( ' + application.ni_number + ' )'"
                        :value="application.ni_number"
                        name="ni_number"
                        type="radio"
                        @updated="handleCopyUpdate"
                    />
                  </div>
                </div>
                <button class="fabric-btn fabric-btn-submit">copy</button>
              </Form>
            </template>
          </EditModal>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else>No records found!</div>
  </div>
</template>
<script>
import {mapGetters, mapActions, mapMutations} from "vuex";
import StatusUpdate from "@/components/form/StatusUpdate";
import LoadingButton from "@/components/form/LoadingButton";

export default {
  name: "ApplicationList",
  props: {
    applicationsProp: {
      default: null
    },
    linksProp: {
      default: null
    },
    metaProp: {
      default: null
    },
    searchApplication: {
      default: null
    },
    preLoaded: {
      type: Boolean,
      default: false
    }
  },
  components: {
    StatusUpdate,
    LoadingButton
  },
  emits: ["selected", "list-status-change", "duplicate-found"],
  data: () => ({
    toId: null,
    toUuid: null,
    processingCopy: false,
    processingTrace: false,
    copyDataParams: {},
    applications: [],
    links: [],
    meta: []
  }),
  watch: {
    applicationsProp(n) {
      this.applications = n;
    },
    linksProp(n) {
      this.links = n;
    },
    metaProp(n) {
      this.meta = n;
    },
    applicationsComp() {
      this.findMaster();
    }
  },
  computed: {
    ...mapGetters(["getUser", "getStatusAppValues"]),
    applicationsComp() {
      return this.searchApplication;
    },
    applicationsCompLength() {
      if (!this.applicationsComp) return;
      return this.applicationsComp.length > 0;
    }
  },
  methods: {
    ...mapActions([
      "getApplications",
      "moveTracedPensions",
      "copyApplicationData",
      "updateApplication"
    ]),
    ...mapMutations(["setApplication"]),
    findMaster() {
      for (let i in this.applicationsComp) {
        if (this.applicationsComp[i].status == "in-progress") {
          this.toId = this.applicationsComp[i].id;
          this.toUuid = this.applicationsComp[i].uuid;
          break;
        } else {
          this.toId = null;
          this.toUuid = null;
        }
      }
    },
    handleCopyUpdate(val) {
      Object.assign(this.copyDataParams, val);
    },
    handleCopyClose() {
      this.processingCopy = false;
    },
    async copyData() {
      if (!confirm("Copy selected data to app id: " + this.toId + "?")) return;
      this.copyDataParams.toUuid = this.toUuid;
      this.copyDataParams.uuid = this.$route.params.auuid;
      this.copyDataParams.type = this.$route.params.type;
      await this.copyApplicationData(this.copyDataParams).catch(err =>
          console.log(err)
      );
      for (let [key, value] of Object.entries(this.$refs)) {
        if (value !== null && /^copyData_/.test(key)) {
          value.show = false;
        }
      }
      this.handleCopyClose();
    },
    async handleMoveTrace(from) {
      if (this.toUuid == null) {
        alert(
            "Please select an application to move to first by setting it as 'In progress' and the others to 'Duplicate'"
        );
        return;
      }
      if (
          !confirm(
              "Move pension traces from this application to to app id: " +
              this.toId +
              "?"
          )
      )
        return;

      this.processingTrace = true;

      let to = this.toUuid;
      let type = this.$route.params.type;
      let uuid = this.$route.params.auuid;

      await this.moveTracedPensions({from, to, type, uuid}).catch(err =>
          console.log(err)
      );

      this.processingTrace = false;
    },
    handleCopy(index) {
      if (this.toUuid == null) {
        alert(
            "Please select an application to copy to first by setting it as 'In progress' and the others to 'Duplicate'"
        );
        return;
      }
      this.processingCopy = true;
      this.$refs["copyData_" + index].show = true;
    },
    handelDuplicate(application) {
      this.$emit("duplicate-found", application);
    },
    handleAssigned() {
      this.$emit("list-status-change");
    },
    async handleStatusChange({status, uuid}) {
      let noUpdate = true;
      // for (let i in this.applicationsComp) {
      // if (this.applicationsComp[i].uuid == uuid) {
      this.toUuid = uuid;
      await this.updateApplication({status, uuid}, noUpdate).catch(err =>
          console.log(err)
      );
      for (let [key, value] of Object.entries(this.$refs)) {
        if (value !== null && /^statusUpdateComponent_/.test(key)) {
          value.edit = false;
          value.updating = false;
        }
      }
      this.$emit("list-status-change");
    },
    handleSelect(application) {
      this.setApplication(application);
      this.$emit("selected", application.uuid);
    }
  }
};
</script>
<style lang="scss">
.duplicate-icon {
  margin-right: 10px;
}
</style>
