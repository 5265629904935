<template>
  <div class="de-duplicate">
    <div class="row">
      <div class="col-12 mb-4">
        <TitleHeader>Duplication Manager</TitleHeader>
      </div>
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-body">
            <ApplicationDuplicateList
              :assignment-usage="true"
              :search-application="getDuplicates"
              :min-cols="true"
              @selected="handleApplicationSelect"
              @list-status-change="gatherApplicationData"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import { mapActions, mapGetters, mapMutations } from "vuex";
import ApplicationDuplicateList from "@/components/lists/ApplicationDuplicateList";

export default {
  components: {
    ApplicationDuplicateList
  },
  computed: {
    ...mapGetters(["getDuplicates"])
  },
  methods: {
    ...mapActions(["fetchDuplicates"]),
    ...mapMutations(["setApplicationUuid"]),
    handleApplicationSelect(uuid) {
      this.setApplicationUuid(uuid);
      this.$router.push({
        name: "Application Focus",
        params: {
          uuid: uuid
        }
      });
    },
    async gatherApplicationData() {
      await this.fetchDuplicates({
        uuid: this.$route.params.auuid,
        type: this.$route.params.type
      });
    }
  },
  async beforeMount() {
    this.gatherApplicationData();
  }
};
</script>
